import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';

import AngledSection from '../components/layout/AngledSection';
import ContactFormModalTrigger from '../components/contact/ContactFormModalTrigger';
import CustomerBenefits from '../components/marketing/CustomerBenefits';
import DemoCallout from '../components/demo/DemoCallout';
import DemoReminder from '../components/demo/DemoReminder';
import FadeIn from '../components/transitions/FadeIn';
import FadeInUp from '../components/transitions/FadeInUp';
import HomeIntroPhoto from '../components/home/HomeIntroPhoto';
import Integrations from '../components/integrations/Integrations';
import Layout from '../components/layout';
import OperationalBenefits from '../components/marketing/OperationalBenefits';
import SEO from '../components/seo';
import Screenshots from '../components/marketing/Screenshots';
import StrengthPoints from '../components/marketing/StrengthPoints';
import ProductCards from '../components/product-cards/ProductCards';
import TextCarousel from '../components/text/TextCarousel';
import theme from '../constants/theme/theme';
import Products from '../constants/products/products';

const useStyles = makeStyles({
	intro: {
		alignItems: 'center',
		display: 'flex',
		minHeight: '520px',
		textAlign: 'left',
		[theme.breakpoints.down('sm')]: {
			display: 'block',
			paddingBottom: '0',
			textAlign: 'center',
		},
	},
	introContainer: {
		overflow: 'hidden',
		[theme.breakpoints.down('xs')]: {
			marginTop: '-7px',
		},
	},
	introText: {
		width: 'calc(100% - 800px)',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	leftIntroText: {
		minWidth: '520px',
		zIndex: '2',
		position: 'relative',
		[theme.breakpoints.down('xs')]: {
			minWidth: '100%',
		}
	},
	introTextHeading: {
		lineHeight: '1em',
		[theme.breakpoints.down('xs')]: {
			maxWidth: '325px',
			display: 'inline-block'
		},
	},
	introVideo: {
		borderRadius: '8px',
		display: 'block',
		overflow: 'auto',
		margin: '0 auto',
		maxWidth: '600px',
		width: '100%',
		height: '300px',
	},
	introVideoWrapper: {
		position: 'relative',
		border: `8px solid ${theme.palette.primary.dark}`,
		borderRadius: '16px',
		maxWidth: '600px',
		width: '100%',
		margin: '0 auto',
		'&::before': {
			position: 'absolute',
			content: '""',
			width: 'calc(100% + 32px)',
			height: 'calc(100% + 32px)',
			top: '-16px',
			left: '-16px',
			border: `8px solid ${theme.palette.primary.main}`,
			borderRadius: '24px',
			pointerEvents: 'none',
		},
		'&::after': {
			position: 'absolute',
			content: '""',
			width: 'calc(100% + 48px)',
			height: 'calc(100% + 48px)',
			top: '-24px',
			left: '-24px',
			border: `8px solid ${theme.palette.primary.light}`,
			borderRadius: '32px',
			pointerEvents: 'none',
		}
	},
	productCardContainer: {
		minHeight: '782px'
	},
	integrationCardContainer: {
		minHeight: '600px'
	},
});

const IndexPage = () => {
	const classes = useStyles();
	const productCardSectionRef = React.createRef();

	const allProducts = Object.keys(Products).map((productKey, index) => {
		return Products[productKey];
	}).sort((a, b) => a.priority - b.priority);

	const learnMore = e => {
		e.preventDefault();

		productCardSectionRef.current.scrollIntoView({ behavior: 'smooth' })
	};

	return (
		<Layout>
			<SEO title="Dry Stack Marina Software" keywords="speedydock,speedy dock,dry stack marina management,drystack marina management software,dry stack marina,dry storage marina software,dry storage marina app,drystack app,drystack software,drystack marina app,marina software,boat launch app,marina app,dry slip app,dry dock app,boat dispatch,boat club,speedydock,marina logistics,marina,management,software,drystack,reservations,boatel,boatel software,boatel app,apps,application,applications,smart,phone,smartphone,scheduler,valet,concierge,boat,boating,convenient,convenience,service,marina,marinas,storage,boat yard,dry-dock,dry-docking,dry rack marina software,marina forklifts,boat launch and retrieval systems" />
			<AngledSection
				color={theme.palette.secondary.dark}
				isAngledTop={false}
			>
				<Box pt={8}>
					<Container className={classes.introContainer}>
						<FadeIn timeout={200}>
							<Box className={classes.intro} pb={8} position="relative" pt={8}>
								<Box className={classes.introText}>
									<div className={classes.leftIntroText}>
									<Typography variant="h3" component="h1">
										<Box className={classes.introTextHeading} color="primary.main">
											<TextCarousel
												strings={[
													{
														paletteColor: Products.DRY_STACK.paletteColor,
														text: `${Products.DRY_STACK.name}.`,
													},
													{
														paletteColor: Products.BOAT_CLUB.paletteColor,
														text: `${Products.BOAT_CLUB.name}.`,
													},
													/*{
														paletteColor: Products.ONLINE_SALES.paletteColor,
														text: `${Products.ONLINE_SALES.name}.`,
													},*/
												]}
											/>
										</Box>
									</Typography>
									<Box pb={3} pt={3}>
										<Typography paragraph variant="subtitle1">
											Software for dry stack marinas and boat clubs. SpeedyDock simplifies marina operations to save time on every launch, retrieval, and reservation. No more phone calls or scheduling issues.
										</Typography>
									</Box>
									<Box display="inline-block" pr={2}>
										<ContactFormModalTrigger color="primary" variant="contained">
											Free Demo
										</ContactFormModalTrigger>
									</Box>
									<Button color="primary" variant="outlined" onClick={ learnMore }>
											Learn More
									</Button>
									</div>
									<HomeIntroPhoto />
								</Box>
							</Box>
						</FadeIn>
					</Container>
				</Box>
			</AngledSection>
			<Box pb={12} pt={10} bgcolor="secondary.dark" ref={productCardSectionRef} className={classes.productCardContainer}>
				<FadeInUp threshold={0} timeout={250}>
					<Container>
						<ProductCards />
					</Container>
				</FadeInUp>
			</Box>
			<AngledSection color={theme.palette.secondary.dark}>
				<Box pb={14} pt={14} bgcolor="primary.main" className={classes.productCardContainer}>
					<FadeInUp>
						<Container>
							<Box pb={8}>
								<Typography align="center" variant="h3" color="secondary">
									What you get with SpeedyDock
								</Typography>
							</Box>
							<OperationalBenefits />
						</Container>
					</FadeInUp>
				</Box>
			</AngledSection>
			<AngledSection isAngledTop={false}>
				<Box pb={12} pt={12} bgcolor="secondary.dark" className={classes.productCardContainer}>
					<FadeInUp>
						<Container>
							<Box pb={8} color="primary">
								<Typography align="center" variant="h3">
									What your customers get
								</Typography>
							</Box>
							<CustomerBenefits />
						</Container>
					</FadeInUp>
				</Box>
			</AngledSection>
			<Box pb={3} className={classes.integrationCardContainer}>
				<Integrations product={ Products.DRY_STACK.name } />
			</Box>
			<AngledSection isAngledBottom={false}>
				<Box pb={12} pt={12} bgcolor="primary.main" className={classes.productCardContainer}>
					<FadeInUp>
						<Container>
							<Box pb={4}>
								<Typography align="center" variant="h3" color="secondary">
									Why choose SpeedyDock?
								</Typography>
								<Box pt={8}>
									<StrengthPoints />
								</Box>
							</Box>
						</Container>
					</FadeInUp>
				</Box>
			</AngledSection>
			<Box className={classes.productCardContainer}>
				<Screenshots products={allProducts} />
			</Box>
			{/*<FadeInUp>
				<Box pb={14} pt={12}>
					<Container>
						<Box pb={8}>
							<Typography align="center" variant="h3">
								See us in action
							</Typography>
						</Box>
						<Box className={classes.introVideoWrapper}>
							<iframe className={classes.introVideo} src="https://www.youtube.com/embed/0Z-J7tBOg70?rel=0" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
						</Box>
					</Container>
				</Box>
			</FadeInUp>*/}
			<Box pb={3}>
				<DemoCallout bgColor="secondary.light" />
			</Box>
			<DemoReminder />
		</Layout>
	);
};

export default IndexPage;
