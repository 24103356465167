import React from 'react';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';

const TextCarousel = ({ strings, timeout = 4000 }) => {
    const [activeIndex, _setActiveIndex] = React.useState(0);
    const [isVisible, setIsVisible] = React.useState(true);
    const activeIndexRef = React.useRef(0);

    const setActiveIndex = index => {
        _setActiveIndex(index);
        activeIndexRef.current = index;
    };

    React.useEffect(() => {
        const carouselTimer = setInterval(() => {
            setIsVisible(false);

            setTimeout(() => {
                setActiveIndex(activeIndexRef.current === strings.length - 1 ? 0 : activeIndexRef.current + 1);
                setIsVisible(true);
            }, 250);
        }, timeout);

        return () => clearInterval(carouselTimer);
    }, [strings, timeout]);

    return (
        <>
            {strings.map(({ paletteColor, text }, i) => (
                <Fade in={isVisible && i === activeIndex} key={text}>
                    <Box color={i === 0 ? `${paletteColor}.lighter` : `${paletteColor}.dark`} display={i === activeIndex ? 'block' : 'none'}>
                        { text }
                    </Box>
                </Fade>
            ))} 
            <Box color={'primary.main'} pt={1}>Simplified.</Box>
        </>
    );
};

export default TextCarousel;
